/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { Card, Stack } from "@mui/material";
// @mui material components
import Grid from "@mui/material/Grid";
import colors from "assets/theme/base/colors";

// Vision UI Dashboard React base styles
import linearGradient from "assets/theme/functions/linearGradient";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiProgress from "components/VuiProgress";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard React contexts
import { setDirection, useVisionUIController } from "context";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import BarChart from "examples/Charts/BarCharts/BarChart";

// Data
import LineChart from "examples/Charts/LineCharts/LineChart";
import Footer from "examples/Footer";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import OrderOverview from "./components/OrderOverview";
import Products from "./components/Products";

// Dashboard layout components
import { lineChartOptionsDashboard } from "layouts/rtl/data/lineChartOptions";
import { useEffect, useState } from "react";
// React icons
import { IoIosRocket } from "react-icons/io";
import {
  IoAlertCircle,
  IoCheckmarkDone,
  IoPower,
  IoTrash,
} from "react-icons/io5";
import axios from "axios";
import { apiList } from "../../variables/api";
import { persianDate } from "../../utils/PersianDate";

function Product() {
  const { gradients } = colors;
  const { cardContent } = gradients;
  const [, dispatch] = useVisionUIController();
  const [dashboardData, setDashboardData] = useState(null);
  const [products, setProducts] = useState(null);
  const [productFilters, setProductFilters] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const getDashboardData = async () => {
    setIsLoading(true);
    const result = await axios({
      method: "get",
      url: apiList["dashboard-data"]+'/DigikalaService',
    });
    if (result && result.data) {
      setDashboardData(result.data);
    }
    setIsLoading(false);
  };
  const getProducts = async () => {
    setIsLoading(true);
    const result = await axios({
      method: "post",
      url: apiList.products.search,
      data:productFilters,
    });
    if (result && result.data) {
      setProducts(result.data);
    }
    setIsLoading(false);
  };

  // Changing the direction to rtl
  useEffect(() => {
    setDirection(dispatch, "rtl");
    getDashboardData();
    return () => setDirection(dispatch, "ltr");
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>

        <Grid container mb={3} spacing={3}>
          <Grid item xs={12} md={6} lg={12}>
            <Products syncLogData={(dashboardData && dashboardData?.ok ? dashboardData?.recent : null)} doRefresh={getDashboardData} moreRoutePath={'/details?mn=DigikalaService'} />
          </Grid>
        </Grid>

      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Product;
