import { Card, CardActions, CardHeader, Dialog, ListItemAvatar, ListItemSecondaryAction } from "@mui/material";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import { Container, Draggable } from "react-smooth-dnd";

import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import { CloudUpload, DragHandle, FilePresent, Layers } from "@mui/icons-material";
import React, { useState } from "react";
import { arrayMoveImmutable } from "array-move";
import VuiTypography from "../../../../components/VuiTypography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import VuiSwitch from "../../../../components/VuiSwitch";
import VuiBox from "../../../../components/VuiBox";
import axios from "axios";
import { apiList } from "../../../../variables/api";
import CircularProgress from "@mui/material/CircularProgress";


// fake data generator
const getItems = (count) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k}`,
    primary: `item ${k}`,
    secondary: k % 2 === 0 ? `Whatever for ${k}` : undefined
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)"
  })
});

const getListStyle = (isDraggingOver) => ({
  //background: isDraggingOver ? 'lightblue' : 'lightgrey',
});
export function HybridExcelUploadModal({isOpen,onClose,moduleNames}){
  const [items, setItems] = useState([
    { id: "1",order:1, text: "جی بوک" ,moduleName:'GbookService',isOn:false},
    { id: "2",order:2, text: "بازار کتاب",moduleName:'BazarketabService',isOn:false },
    { id: "3",order:3, text: "سی بوک" ,moduleName:'SibookService',isOn:false},
  ]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileUpload = async (event) => {
    if (selectedFile) {
      try {
        setIsUploading(true);
        const formData = new FormData();
        formData.append('excel_file', selectedFile);

        const selectedModules=items.filter((item)=>item.isOn)
        if(!selectedModules.length) return;
        const url=selectedModules.length===1?
          apiList['create-queue-by-excel']+`/${selectedModules[0].moduleName}`:
          apiList['create-queue-by-excel']+`/null`
        ;
        if(selectedModules.length>1)
          for(let i=0;i<selectedModules.length;i++){
            const {moduleName,order}=selectedModules[i];
            formData.append(`multipleModule[${i}]`,JSON.stringify({ moduleName,order }));
          }


        const response = await axios.post(url, formData, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            //setProgress(percentCompleted);
          },
        });

        // Handle the response from the server if needed
        console.log('Upload successful:', response.data);
        //close modal
        onClose();
      } catch (error) {
        console.error('Upload failed:', error);
      } finally {
        //setProgress(0); // Reset progress after completion or failure
        setIsUploading(false);
      }
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };
  const onDrop = ({ removedIndex, addedIndex }) => {
    console.log({ removedIndex, addedIndex });
    setItems(items => {
      const newItems= arrayMoveImmutable(items, removedIndex, addedIndex);
      for(let key in newItems)
        newItems[key]['order']=(+key)+1;
      return newItems;
    });
  };
  const handleCheck = (newValue,id) => {
    console.log('new value:',newValue);
    setItems((prev)=>{
      return prev.map((item)=>{
        if(item.id===id) item.isOn=newValue;
        return item;
      })
    })
  };

  return <Dialog maxWidth={'md'} open={isOpen} onClose={onClose} fullWidth={true}  sx={{padding:'10px',backgroundColor:'rgba(255, 255, 255, 0) !important','& .MuiPaper-root': {
      background: 'rgba(255, 255, 255, 0) !important'
    }}}>
    <Card>
      <VuiTypography variant="title" color="white">
        ایجاد عملیات دسته ای
      </VuiTypography>
      <Divider variant={'fullWidth'} sx={{background:'white'}} color={'white'} />
      <VuiTypography variant="caption" color="white">
        ترتیب جستجو از بالا به پایین مشخص کنید
      </VuiTypography>
      <Grid container flex={1} mt={2} spacing={2}>
        <Grid item xs={12} md={6}>
          <List>
            <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
              {items.map(({ id, text,isOn,order }) => (
                <Draggable key={id}>
                  <ListItem mt={2} sx={{marginTop:2}}>
                    <ListItemIcon className="drag-handle" >
                      <DragHandle color={'white'} sx={{cursor:'move'}} />
                    </ListItemIcon>
                    <VuiTypography variant="caption" color="white">
                      {`${order}`+'. '+text}
                    </VuiTypography>
                    <ListItemSecondaryAction>
                      <VuiBox >
                        <VuiSwitch color="info" size={''} checked={isOn} onChange={(e) => {
                          handleCheck(!isOn,id);
                        }} />
                      </VuiBox>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Draggable>
              ))}
            </Container>
          </List>
        </Grid>
        <Grid item xs={12} md={6} alignItems={'center'} justifyContent={'center'} display={'flex'}>
          <div>
            <input
              type="file"
              accept=".xlsx"
              style={{ display: 'none' }}
              id="hybrid-file-upload-input"
              onChange={handleFileChange}
            />
            <label htmlFor="hybrid-file-upload-input">
              <Button component="span" variant="contained" color="info">
                <FilePresent  sx={{ mr: 1 }} />
                <VuiTypography variant="" >
                  {selectedFile ? selectedFile.name : 'انتخاب فایل'}
                </VuiTypography>
              </Button>
            </label>
          </div>
        </Grid>
      </Grid>
      <CardActions sx={{marginTop:3,textAlign:'center'}}>
        <Grid flex={1}>
          <Button component="span" variant="contained" color="primary" onClick={handleFileUpload} disabled={isUploading}>
            {
              isUploading?
                <CircularProgress color={'white'} title={'در حال آپلود'} size={'1.5em'} sx={{mr:1}}/>:
                <>
                  <CloudUpload color={'white'} sx={{ mr: 1 }} />
                  <VuiTypography variant="" color="white">
                    آپلود
                  </VuiTypography>
                </>
            }

          </Button>
        </Grid>
      </CardActions>
    </Card>
  </Dialog>
}