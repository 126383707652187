//export const baseUrl='http://localhost:3000';

import { ApiBaseURL } from "../utils/Network";

export const baseUrl=ApiBaseURL;
export const apiList={
  'dashboard-data':baseUrl+'/api/dashboard-data',
  'sync-log-search':baseUrl+'/sync-log/search',
  'get-settings':baseUrl+'/api/get-settings',
  'set-setting':baseUrl+'/api/set-setting',
  'search-book-queues':baseUrl+'/datamining/search-book-queues',
  'search-book':baseUrl+'/datamining/search-book',
  'create-queue-by-excel':baseUrl+'/datamining/create-queue-by-excel',
  'download-image':baseUrl+'/datamining/download-image',
  products:{
    search:baseUrl+'/product/search',
    update:baseUrl+'/product/update',
    searchCategories:baseUrl+'/product/search-categories',
    mapCategory:baseUrl+'/product/map-category',//:categoryId/:remoteCategoryId
  }
}

export const downloadBookQueueBaseUrl=baseUrl+'/datamining/download-queue';