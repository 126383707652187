/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useHistory } from "react-router-dom";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgSignIn from "assets/images/signInImage.png";
import { useSnackbar } from "material-ui-snackbar-provider";
import { setUser, useVisionUIController } from "../../../context";
import CircularProgress from "@mui/material/CircularProgress";
import ApiList from "../../../utils/ApiList";
import Network from "../../../utils/Network";

function SignIn() {
  const snackbar = useSnackbar()
  const history=useHistory();
  const [isLocal, setIsLocal] = useState(!!window?.localStorage?.getItem("s_is_local"));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const handleSetLocal = () =>{
    if(window?.localStorage?.getItem("s_is_local")) {
      window?.localStorage?.removeItem("s_is_local");
      setIsLocal(false);
    }
    else {
      window?.localStorage?.setItem("s_is_local", "1");
      setIsLocal(true);
    }
    window?.location?.reload();
  };
  const [controller, dispatch] = useVisionUIController();
  const { user } = controller;

  const handleSubmit=async ()=>{
    if(username.trim()==='' || password.trim()==='')
    {
      return snackbar.showMessage("لطفا ورودی های خود را کنترل کنید");
    }
    setIsSubmitting(true);
    const network = new Network();
    try{
      const result=await network.CallApi({ url: ApiList.auth.login,body:{
          email:username.trim(),
          password:password.trim(),
        } });
      if(result.ok) {
        setUser(dispatch,result.user)
        localStorage.setItem('s_token',JSON.stringify(result.token))
        snackbar.showMessage("ورود موفقیت آمیز");
        //history.push('/dashboard');
        window.location.href='/dashboard';//reload all states
      }
      else
        snackbar.showMessage('عملیات ناموفق',null,null)
    }
    catch (e) {
      console.log(e);
      snackbar.showMessage('عملیات ناموفق',null,null)
    }
    setIsSubmitting(false);
  }

  const handleAutoRedirectToHome=()=>{
    if(user)
      history.replace('/dashboard');
  }

  useEffect(()=>{
    //todo:uncomment after debug
    //handleAutoRedirectToHome();
  },[]);

  return (
    <CoverLayout
      title="خوش آمدید"
      color="white"
      description="جهت ورود به سامانه نام کاربری و گذرواژه خود را وارد نمایید"
      premotto="سامانه مدیریت"
      motto="شـازده کـوچـولـو"
      image={bgSignIn}
      navbar={false}
    >
      <VuiBox component="form" role="form">
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              نام کاربری
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius={borders.borderRadius.lg}
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput type="text" placeholder="نام کاربری خود را وارد نمایید" fontWeight="500" onChange={(e)=>setUsername(e.target.value)} />
          </GradientBorder>
        </VuiBox>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              گذرواژه
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="password"
              placeholder="گذرواژه خود را وارد نمایید"
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              onChange={(e)=>setPassword(e.target.value)}
              onKeyPress={(event) => {
                if (event.charCode === 13){
                  handleSubmit()
                }}}
            />
          </GradientBorder>
        </VuiBox>

        <VuiBox display="flex" alignItems="center">
          <VuiSwitch color="info" checked={isLocal} onChange={handleSetLocal} />
          <VuiTypography
            variant="caption"
            color="white"
            fontWeight="medium"
            onClick={handleSetLocal}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;حالت لوکال
          </VuiTypography>
        </VuiBox>

        <VuiBox mt={4} mb={1}>
          <VuiButton disabled={isSubmitting} onClick={()=>{handleSubmit()}} color="info" fullWidth>
            {isSubmitting?<CircularProgress size={14} />:'ورود'}
          </VuiButton>
        </VuiBox>

      </VuiBox>
    </CoverLayout>
  );
}

export default SignIn;
