// @mui material components
import Tooltip from "@mui/material/Tooltip";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";
import VuiProgress from "components/VuiProgress";

// Images
import AdobeXD from "examples/Icons/AdobeXD";
import Atlassian from "examples/Icons/Atlassian";
import Slack from "examples/Icons/Slack";
import Spotify from "examples/Icons/Spotify";
import Jira from "examples/Icons/Jira";
import Invision from "examples/Icons/Invision";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoJira from "assets/images/small-logos/logo-jira.svg";
import logoInvesion from "assets/images/small-logos/logo-invision.svg";
import avatar1 from "assets/images/avatar1.png";
import avatar2 from "assets/images/avatar2.png";
import avatar3 from "assets/images/avatar3.png";
import avatar4 from "assets/images/avatar4.png";
import { persianAgo, persianDate } from "../../../../../utils/PersianDate";
import { ProductRemoteProviders } from "../../../../../utils/enums";
import VuiSwitch from "../../../../../components/VuiSwitch";
import axios from "axios";
import { apiList } from "../../../../../variables/api";

export default function data({data,updateSyncStatus}) {

  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <VuiAvatar
          src={image}
          alt="name"
          size="xs"
          sx={{
            border: ({ borders: { borderWidth }, palette: { dark } }) =>
              `${borderWidth[2]} solid ${dark.focus}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  const rows=data && data.length?data.map((item)=>{
    return {
      "محصول": (
      <VuiBox>
        <VuiTypography pl="16px" color="white" variant="caption" sx={{display: "block" }} fontWeight="medium">
          {item?.data?.name??'نامشخص'}
        </VuiTypography>
        <VuiTypography pl="16px" color="white" variant={'caption'} sx={{display: "block" }}  fontWeight="small">
          {item?.data?.reference??'بارکد نامشخص'}
        </VuiTypography>
      </VuiBox>
    ),
      "کد مقصد": (
      <VuiBox display="flex" py={1}>
        <VuiTypography variant="caption" color="white" fontWeight="normal" fontSize={12}>
          {item?.lastSyncData?.length ?
            item?.lastSyncData?.map((syncData)=>{
              return <VuiTypography color={'white'} variant="subtitle" fontWeight="bold">
                {ProductRemoteProviders[syncData?.remoteProvider]} - {syncData?.remoteProductId}
              </VuiTypography>
            })
            :'نامشخص'}
        </VuiTypography>
      </VuiBox>
    ),
      "قیمت": (
      <VuiBox display="flex" py={1}>
        <VuiTypography variant="caption" color={item?.data?.sale_price && parseFloat(item?.data?.sale_price)!==parseFloat(item?.data?.price) ?"error":"success"} sx={{textDecoration:item?.data?.sale_price && parseFloat(item?.data?.sale_price)!==parseFloat(item?.data?.price)?'line-through':'none'}} fontWeight="bold">
          {item?.data?.price!==undefined?`${parseInt(parseFloat(item?.data?.price).toFixed(0)).toLocaleString('fa-IR')}تومان`:'نامشخص'}
        </VuiTypography>&nbsp;
        {item?.data?.sale_price && parseFloat(item?.data?.sale_price)!==parseFloat(item?.data?.price)  && <VuiTypography variant="caption" color="success" fontWeight="bold">
          {item?.data?.sale_price !== undefined ? `${parseInt(parseFloat(item?.data?.sale_price).toFixed(0)).toLocaleString("fa-IR")}تومان` : "نامشخص"}
        </VuiTypography>}
      </VuiBox>
    ),
      "وضعیت": (
      <VuiBox display="flex" py={1}>
        <VuiTypography variant="caption" color="white" fontWeight="bold">
          {item?.data?.active==='1'?'فعال':'غیرفعال'}
        </VuiTypography>&nbsp;/&nbsp;
        <VuiTypography variant="caption" color={item?.stockData?.quantity!=='0'?"success":'error'} fontWeight="bold">
          {item?.stockData?.quantity!=='0'?`${item?.stockData?.quantity}`:' ناموجود'}
        </VuiTypography>
      </VuiBox>
    ),
      "آخرین بروزرسانی رکورد": (
      <VuiTypography variant="caption" color="white" fontWeight="bold">
        {persianDate(new Date(item?.updatedAt))}
      </VuiTypography>
    ),
      'آخرین وضعیت سینک': (
      <VuiBox width="8rem" textAlign="center">

          {item?.lastSyncData?.length ?
            item?.lastSyncData?.map((syncData)=>{
              return <Tooltip title={syncData?.wasOk?'موفق':syncData?.data?.message?.[0]?.message??(Object.values(syncData?.data?.message)[0][0]??'خطا نامشخص')}><VuiTypography color={!!syncData?.wasOk?'success':'error'} variant="caption" fontWeight="bold">
                {ProductRemoteProviders[syncData?.remoteProvider]} - {!!syncData?.wasOk?'موفق':'ناموفق'} - {persianDate(new Date(syncData?.date))}
              </VuiTypography></Tooltip>
            })
            :'نامشخص'}
      </VuiBox>
    ),
      'عملیات': (
      <VuiBox width="8rem" textAlign="center" display={'flex'} justifyContent={'space-around'}>
        <VuiBox mt={0.25}>
          <VuiSwitch color="info" checked={!!item?.isSyncActive} onChange={(e) => {
            updateSyncStatus(item?._id,!item?.isSyncActive)
          }} />
        </VuiBox>
        <VuiBox width="80%" ml={2}>
          <VuiTypography fontSize={10} variant={'caption'} fontWeight="regular" color="text">
            فعالسازی سینک
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    ),
    }
  }):[]
  return {
    columns: [
      { name: "محصول", align: "left" },
      { name: "کد مقصد", align: "left" },
      { name: "قیمت", align: "left" },
      { name: "وضعیت", align: "center" },
      { name: "آخرین بروزرسانی رکورد", align: "center" },
      { name: "آخرین وضعیت سینک", align: "center" },
      { name: "عملیات", align: "center" },
    ],

    rows,
  };
}
