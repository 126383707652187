import React from 'react';
import Dialog from '@mui/material/Dialog';
import VuiButton from "../VuiButton";
import { Card } from "@mui/material";
import VuiTypography from "../VuiTypography";
import Grid from "@mui/material/Grid";

const GeneralDialog = ({ open, onClose, onConfirm, title, body,confirmTitle='تایید',fullContentMode=false,maxWidth='md' }) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };
  if(fullContentMode){
    return <Dialog maxWidth={maxWidth} open={open}  onClose={onClose} PaperProps={{ style: { background: 'transparent', boxShadow: 'none' } }}>
      {body}
    </Dialog>
  }
  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { background: 'transparent', boxShadow: 'none' } }}>
      <Card py={{padding:'50px'}} >
        {title && <VuiTypography fontWeight="bold" color="white" fontSize={"24px"}>{title}</VuiTypography>}
        {body}
        <Grid container mt={4} justifyContent={'flex-end'} spacing={2}>
          {!!onClose && <Grid item>
            <VuiButton onClick={onClose} color="light">
              بستن
            </VuiButton>
          </Grid>}
          {!!onConfirm && <Grid item>
            <VuiButton onClick={handleConfirm} variant={"contained"} color="primary">
              {confirmTitle}
            </VuiButton>
          </Grid>}
        </Grid>
      </Card>

    </Dialog>
  );
};

export default GeneralDialog;
