import TimeAgo from "javascript-time-ago";
import fa from 'javascript-time-ago/locale/fa'
import moment from "jalali-moment";

export function persianAgo(jsDateObject, locale='fa-IR'){
    TimeAgo.addLocale(fa)
    TimeAgo.setDefaultLocale(fa)
    const timeAgo = new TimeAgo(locale);
    return timeAgo.format(jsDateObject);
}

export function persianDate(jsDateObject,format='YYYY/MM/DD HH:mm'){
    return moment(jsDateObject).locale('fa').format(format)
}