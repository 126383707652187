/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSwitch from "components/VuiSwitch";
import axios from "axios";
import { apiList } from "../../../../variables/api";

function PlatformSettings() {
  const [followsMe, setFollowsMe] = useState(true);
  const [answersPost, setAnswersPost] = useState(false);
  const [mentionsMe, setMentionsMe] = useState(true);
  const [newLaunches, setNewLaunches] = useState(false);
  const [productUpdate, setProductUpdate] = useState(true);
  const [newsletter, setNewsletter] = useState(true);
  const [mails, setMails] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [refresh,setRefresh]=useState(false);
  const [allSettings,setAllSettings]=useState(null);

  const [digikalaSettings,setDigikalaSettings]=useState(null)
  const [snappSettings,setSnappSettings]=useState(null)
  const [basalamSettings,setBasalamSettings]=useState(null)

  const getAllSettings = async () => {
    setIsLoading(true);
    const result = await axios({
      method: "get",
      url: apiList["get-settings"],
    });
    if (result && result.data && result?.data.settings) {
      setAllSettings(result.data);
      if(result.data.ok) {
        setDigikalaSettings(result?.data?.settings?.filter((item)=>item.key==='DigikalaService')?.[0]);
        setSnappSettings(result?.data?.settings?.filter((item)=>item.key==='SnappService')?.[0]);
        setBasalamSettings(result?.data?.settings?.filter((item)=>item.key==='BasalamService')?.[0]);
      }
    }
    setIsLoading(false);
  };

  const updateSettings = async (update) => {
    //fast update for ui
    if(update?.key==='DigikalaService') {
      setDigikalaSettings(update);
    }
    if(update?.key==='SnappService') {
      setSnappSettings(update);
    }
    if(update?.key==='BasalamService') {
      setBasalamSettings(update);
    }

    setIsLoading(true);
    const result = await axios({
      method: "post",
      url: apiList["set-setting"]+`/${update?._id}`,
      data:update
    });
    if (result && result.data && result?.data?.settings) {
      if(result.data.ok) {
        if(update?.key==='DigikalaService') {
          setDigikalaSettings((prev)=>({...result.data?.setting}));
        }
        if(update?.key==='SnappService') {
          setSnappSettings((prev)=>({...result.data?.setting}));
        }
        if(update?.key==='BasalamService') {
          setBasalamSettings((prev)=>({...result.data?.setting}));
        }
      }
    }
    setIsLoading(false);
  };

  // Changing the direction to rtl
  useEffect(() => {
    getAllSettings();
  }, [0]);

  useEffect(() => {

  }, [digikalaSettings]);

  return (
    <Card sx={{ minHeight: "490px", height: "100%" }}>
      <VuiBox mb="26px">
        <VuiTypography variant="lg" fontWeight="bold" color="white" textTransform="capitalize">
          تنظیمات پلتفرم
        </VuiTypography>
      </VuiBox>
      <VuiBox lineHeight={1.25}>
        <VuiTypography
          variant="xxs"
          fontWeight="medium"
          mb="20px"
          color="text"
          textTransform="uppercase"
        >
          دیجیکالا
        </VuiTypography>
        <VuiBox display="flex" mb="14px">
          <VuiBox mt={0.25}>
            <VuiSwitch color="info" checked={!!digikalaSettings?.options?.enabled} onChange={(e) => {
              updateSettings({
                ...digikalaSettings,
                options:{
                  ...digikalaSettings?.options,
                  enabled:!digikalaSettings?.options?.enabled
                }
              })
            }} />
          </VuiBox>
          <VuiBox width="80%" ml={2}>
            <VuiTypography variant="button" fontWeight="regular" color="text">
              فعال/غیرفعال کردن اتوماسیون دیجیکالا
            </VuiTypography>
          </VuiBox>
        </VuiBox>
        <VuiBox display="flex" mb="14px">
          <VuiBox mt={0.25}>
            <VuiSwitch color="info" checked={!!digikalaSettings?.options?.deactivateZeroStockCount} onChange={(e) => {
              updateSettings({
                ...digikalaSettings,
                options:{
                  ...digikalaSettings?.options,
                  deactivateZeroStockCount:!digikalaSettings?.options?.deactivateZeroStockCount,
                }
              })
            }} />
          </VuiBox>
          <VuiBox width="80%" ml={2}>
            <VuiTypography variant="button" fontWeight="regular" color="text">
              محصولات ناموجود را غیرفعال کن
            </VuiTypography>
          </VuiBox>
        </VuiBox>
        <VuiBox mb="6px">
          <VuiTypography variant="xxs" fontWeight="medium" color="text" textTransform="uppercase">
            باسلام
          </VuiTypography>
        </VuiBox>
        <VuiBox display="flex" mb="14px">
          <VuiBox mt={0.25}>
            <VuiSwitch color="info" checked={!!basalamSettings?.options?.enabled} onChange={(e) => {
              updateSettings({
                ...basalamSettings,
                options:{
                  ...basalamSettings?.options,
                  enabled:!basalamSettings?.options?.enabled,
                }
              })
            }} />
          </VuiBox>
          <VuiBox width="80%" ml={2}>
            <VuiTypography variant="button" fontWeight="regular" color="text">
              فعال/غیرفعال کردن اتوماسیون باسلام
            </VuiTypography>
          </VuiBox>
        </VuiBox>
        <VuiBox mb="6px">
          <VuiTypography variant="xxs" fontWeight="medium" color="text" textTransform="uppercase">
            اسنپ
          </VuiTypography>
        </VuiBox>
        <VuiBox display="flex" mb="14px">
          <VuiBox mt={0.25}>
            <VuiSwitch color="info" checked={!!snappSettings?.options?.enabled} onChange={(e) => {
              updateSettings({
                ...snappSettings,
                options:{
                  ...snappSettings?.options,
                  enabled:!snappSettings?.options?.enabled,
                }
              })
            }} />
          </VuiBox>
          <VuiBox width="80%" ml={2}>
            <VuiTypography variant="button" fontWeight="regular" color="text">
              فعال/غیرفعال کردن اتوماسیون اسنپ شاپ
            </VuiTypography>
          </VuiBox>
        </VuiBox>

      </VuiBox>
    </Card>
  );
}

export default PlatformSettings;
