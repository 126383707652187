import Card from "@mui/material/Card";
import axios from "axios";
import { apiList } from "../../../../variables/api";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import borders from "../../../../assets/theme/base/borders";
import radialGradient from "../../../../assets/theme/functions/radialGradient";
import palette from "../../../../assets/theme/base/colors";
import { ListSubheader, Select } from "@mui/material";
import VuiInput from "../../../../components/VuiInput";
import MenuItem from "@mui/material/MenuItem";
import GradientBorder from "../../../../examples/GradientBorder";
import VuiTypography from "../../../../components/VuiTypography";
import { useSnackbar } from "material-ui-snackbar-provider";
import VuiButton from "../../../../components/VuiButton";
import VuiBox from "../../../../components/VuiBox";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function ProductCategoryMapping({onSuccess,onClose}){
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [categories, setCategories] = useState(null);
  const snackbar=useSnackbar();
  const getCategories = async () => {
    setIsLoading(true);
    const result = await axios({
      method: "post",
      url: apiList.products.searchCategories,
    });
    if (result && result.data) {
      setCategories(result.data?.results);
    }
    setIsLoading(false);
  };
  const handleMap = async (categoryId,remoteCategoryProvider,remoteCategoryId) => {
    const result = await axios({
      method: "post",
      url: apiList.products.mapCategory+`/${categoryId}/${remoteCategoryProvider}/${remoteCategoryId}`,
    });
    if (result?.data?.ok && result?.data?.updatedCategory) {
      const updatedCategory=result?.data?.updatedCategory;
      setCategories(prevState=>prevState.map(item=>item?._id===updatedCategory._id?updatedCategory:item));
      snackbar.showMessage('تنظیمات ذخیره شد!')
    }

  };

  function buildSpecialListItems(currentCategory){
    if(!currentCategory?.data?.children?.length)
      return <MenuItem value={currentCategory?.originalId??currentCategory?.id}>{currentCategory?.title}</MenuItem>
    else return [
      <MenuItem disabled value={currentCategory?.originalId??currentCategory?.id}>
        <em>{currentCategory?.title}</em></MenuItem>,
      currentCategory?.data?.children.map(child=>buildSpecialListItems(child))
    ]
  }

  useEffect(() => {
    getCategories()
  }, [0,refresh]);

  return <Card sx={{overflowY:'scroll'}}>
    <VuiTypography color="white" variant="lg" mb="6px" gutterBottom>
      تخصیص دسته بندی های شازده به باسلام
    </VuiTypography>

    {!isLoading?
      <Grid container justifyContent={"space-between"} spacing={2} alignItems={"center"}>
      {categories?.map((item, index) => item.provider === "SHAZDEH" &&
        <Grid key={index} item container xs={12} justifyContent={"space-between"} spacing={2} alignItems={"center"}>
          <Grid item xs={6}>
            <VuiTypography color="white" variant="caption" mb="6px" gutterBottom>
              {item?.title}
            </VuiTypography>
          </Grid>
          <Grid item xs={6}>
            {
              <GradientBorder
                minWidth="100%"
                borderRadius={borders.borderRadius.lg}
                padding="1px"
                backgroundImage={radialGradient(
                  palette.gradients.borderLight.main,
                  palette.gradients.borderLight.state,
                  palette.gradients.borderLight.angle,
                )}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={item?.remoteMappings?.filter(mapping => mapping.remoteProvider === "BASALAM")?.[0]?.remoteId ?? ""}
                  label="دسته بندی با سلام"
                  onChange={(e) => handleMap(item._id,'BASALAM', e.target.value)}
                  input={<VuiInput />}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em>دسته بندی با سلام</em>
                  </MenuItem>
                  {categories?.map((subItem) => (
                    subItem.provider === "BASALAM" && subItem?.data?.parent_id===0 &&
                      [<ListSubheader>{subItem?.title}</ListSubheader>,
                        categories.map(child=>( child?.data?.parent_id===subItem.originalId &&
                          buildSpecialListItems(child)
                      ))]

                  ))}
                </Select>
              </GradientBorder>

            }
          </Grid>
        </Grid>)}
      <Grid item xs={12}>
        <VuiBox mt={4} mb={1}>
          <VuiButton onClick={() => {
            onClose();
          }} color="light" fullWidth>
            بستن
          </VuiButton>
        </VuiBox>
      </Grid>
    </Grid>
      :
    <Grid container justifyContent={'space-around'} alignContent={'center'}>
      <Grid item><CircularProgress /></Grid>
    </Grid>
    }
  </Card>
}