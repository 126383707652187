/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsCheckCircleFill } from "react-icons/bs";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard Materail-UI example components
import Table from "examples/Tables/Table";

// Data
import data from "layouts/product/components/Products/data";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { apiList } from "../../../../variables/api";
import { setDirection } from "../../../../context";
import { Pagination, Select } from "@mui/material";
import * as querystring from "querystring";
import Box from "@mui/material/Box";
import { Autorenew, Category } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { ProductFilters } from "../ProductFilters";
import VuiButton from "../../../../components/VuiButton";
import productCategoryMapping from "../ProductCategoryMapping/ProductCategoryMapping";
import ProductCategoryMapping from "../ProductCategoryMapping/ProductCategoryMapping";
import GeneralDialog from "../../../../components/GeneralDialog";
import { useSnackbar } from "material-ui-snackbar-provider";
import borders from "../../../../assets/theme/base/borders";
import radialGradient from "../../../../assets/theme/functions/radialGradient";
import palette from "../../../../assets/theme/base/colors";
import VuiInput from "../../../../components/VuiInput";
import GradientBorder from "../../../../examples/GradientBorder";

function Products({doRefresh,moreRoutePath,moduleName='ProductService'}) {
  const [menu, setMenu] = useState(null);
  const router=useHistory();
  const [products, setProducts] = useState(false);
  const [categories, setCategories] = useState(false);
  const snackbar=useSnackbar();

  const updateSyncStatus = async (productId,newSyncStatus) => {
    setIsLoading(true);
    const result = await axios({
      method: "post",
      url: apiList.products.update+`/${productId}`,
      data:{isSyncActive:newSyncStatus},
    });
    if (result && result.data) {
      //update that single product
      setProducts((prevState)=> ({
        ...prevState,
        results:prevState?.results?.map(item=>(
          item?._id===result.data?.product?._id?result.data?.product:item
        ))
        })
      );
      snackbar.showMessage('تنظیمات ذخیره شد!')
    }
    setIsLoading(false);
  };

  const { columns, rows } = data({data:products && products?.results?.length?products?.results:null,updateSyncStatus:updateSyncStatus});
  const [isLoading, setIsLoading] = useState(false);
  const [searchBody, setSearchBody] = useState({});
  const [sortBy, setSortBy] = useState('originalId');
  const [searchQueryParams, setSearchQueryParams] = useState({count:50});
  const [refresh,setRefresh]=useState(false);
  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const handleCloseDialog = () => {
    setGeneralDialogConfig((prevState) => ({ ...prevState, open: false }));
  };

  const [generalDialogConfig, setGeneralDialogConfig] = useState({
    open: false,
    /*title: "ارسال پاسخ",*/
    body: null,
    onClose: handleCloseDialog,
    onConfirm: handleCloseDialog,
  });

  const changePage=(page)=>{
    setSearchQueryParams((prev)=>{
      return {...
        prev, page:page
      }});
  }
  const getProducts = async () => {
    setIsLoading(true);
    const result = await axios({
      method: "post",
      url: apiList.products.search,
      data:searchBody,
      params:searchQueryParams
    });
    if (result && result.data) {
      setProducts(result.data);
    }
    setIsLoading(false);
  };

  const getCategories = async () => {
    setIsLoading(true);
    const result = await axios({
      method: "post",
      url: apiList.products.searchCategories,
      data:{provider:'SHAZDEH'}
    });
    if (result && result.data) {
      setCategories(result.data?.results);
    }
    setIsLoading(false);
  };



  // Changing the direction to rtl
  useEffect(() => {
    setProducts([]);
    getProducts();
    getCategories()
  }, [0,searchBody,searchQueryParams,refresh]);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={()=>{
        closeMenu();
        doRefresh();
      }}>رفرش</MenuItem>
      <MenuItem onClick={()=>{
        router.push(moreRoutePath)
      }}>مشاهده نتایج بیشتر</MenuItem>
    </Menu>
  );

  return (
    [<ProductFilters filters={searchBody} setFilters={setSearchBody} categories={categories}/>,
    <Card>
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="32px">
        <VuiBox>
          <VuiTypography color="white" variant="lg" mb="6px" gutterBottom>
            محصولات
          </VuiTypography>
          <VuiBox display="flex" alignItems="center" lineHeight={0}>
            <BsCheckCircleFill color="green" size="15px" />
            <VuiTypography variant="button" fontWeight="regular" color="text" ml="5px">
              &nbsp;<strong>{products?.totalResults??'0'}&nbsp;نتیجه یافت شد</strong>
            </VuiTypography>
          </VuiBox>
        </VuiBox>
        <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="32px">

          <VuiBox px={1}>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle,
              )}
            >
              <Select
                labelId="sort-label"
                id="sort"
                value={searchQueryParams?.sortBy??''}
                label="مرتب سازی"
                onChange={(e)=>setSearchQueryParams(prevState => ({...prevState,sortBy: e.target.value!==''?e.target.value:null}))}
                input={<VuiInput />}
                displayEmpty
              >
                <MenuItem value="">
                  <em>مرتب سازی</em>
                </MenuItem>
                <MenuItem value={'price'}>قیمت</MenuItem>
                <MenuItem value={'data.updatedAt'}>آخرین تغییر</MenuItem>
              </Select>
            </GradientBorder>
          </VuiBox>
          <VuiBox px={1}>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle,
              )}
            >
              <Select
                labelId="sort-direction-label"
                id="sort-direction"
                value={searchQueryParams?.sortDirection??''}
                label="جهت مرتب سازی"
                onChange={(e)=>setSearchQueryParams(prevState => ({...prevState,sortDirection: e.target.value!==''?e.target.value:null}))}
                input={<VuiInput />}
                displayEmpty
              >
                <MenuItem value="">
                  <em>جهت مرتب سازی</em>
                </MenuItem>
                <MenuItem value={1}>صعودی</MenuItem>
                <MenuItem value={-1}>نزولی</MenuItem>
              </Select>
            </GradientBorder>
          </VuiBox>
          <VuiBox color="text" px={2}>
            {/*<Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={()=>setRefresh((prev)=>!prev)}>
            more_vert
          </Icon>*/}
            <VuiButton color={'light'} onClick={()=>setRefresh((prev)=>!prev)}>
              <Autorenew/>&nbsp;تازه سازی
            </VuiButton>
          </VuiBox>
          <VuiBox color="text" px={2}>
            <VuiButton variant={'contained'} color={'info'} onClick={() => {
              setGeneralDialogConfig((prevState) => ({
                ...prevState,
                body: <ProductCategoryMapping
                  onSuccess={generalDialogConfig.onClose} onClose={generalDialogConfig.onClose}
                />,
                open: true,
              }));
            }}><Category />&nbsp;
              مپینگ دسته ها</VuiButton>
          </VuiBox>
        </VuiBox>

        {renderMenu}
      </VuiBox>
      <VuiBox
        sx={{
          "& th": {
            borderBottom: ({ borders: { borderWidth, borderColor } }) =>
              `${borderWidth[1]} solid ${borderColor.grey}`,
          },
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                `${borderWidth[1]} solid ${borderColor.grey}`,
            },
          },
        }}
      >
        <Table columns={columns} rows={rows} />
        <Box flex xs={12} sx={{display:'flex',textAlign:'center'}} alignContent={'center'} justifyContent={'center'} alignItems={'center'}>
          <Pagination color={'primary'}  count={products?.totalResults?Math.ceil(products?.totalResults/products?.perPage):100} variant="outlined" onChange={(e,p)=>changePage(p)} />
        </Box>
      </VuiBox>
      <GeneralDialog maxWidth={"sm"} fullContentMode={true} body={generalDialogConfig.body}
                     open={generalDialogConfig.open}
                     onClose={generalDialogConfig.onClose} />
    </Card>]
  );
}

export default Products;
