/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// React icons
import { BsCheckCircleFill } from "react-icons/bs";
import { FaBell, FaCross } from "react-icons/fa";
import { IoLogoCss3 } from "react-icons/io";
import { FaShoppingCart } from "react-icons/fa";
import { BsCreditCardFill } from "react-icons/bs";
import { SiDropbox } from "react-icons/si";

// Vision UI Dashboard React example components
import TimelineItem from "examples/Timeline/TimelineItem";
import AdobeXD from "examples/Icons/AdobeXD";

// Vision UI Dashboard theme imports
import palette from "assets/theme/base/colors";
import { persianDate } from "../../../../utils/PersianDate";
import { IoBan } from "react-icons/io5";
import { Pagination } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import axios from "axios";
import { apiList } from "../../../../variables/api";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { Autorenew } from "@mui/icons-material";

function OrdersOverview({moduleName='DigikalaService'}) {

  const router=useHistory();
  const [syncLogData, setSyncLogData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchBody, setSearchBody] = useState({moduleName,isDeactivated:true});
  const [searchQueryParams, setSearchQueryParams] = useState({count:10});
  const [refresh,setRefresh]=useState(false);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const changePage=(page)=>{
    setSearchQueryParams((prev)=>{
      return {...
          prev, page:page
      }});
  }
  const getSyncLogData = async () => {
    setIsLoading(true);
    const result = await axios({
      method: "post",
      url: apiList["sync-log-search"],
      data:searchBody,
      params:searchQueryParams
    });
    if (result && result.data) {
      setSyncLogData(result.data);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getSyncLogData();
  }, [0,searchBody,searchQueryParams]);
  return (
    <Card className="h-100">
      <VuiBox mb="16px" display="flex" justifyContent="space-between" alignItems="center" mb="32px">
        <VuiBox>
          <VuiTypography variant="lg" fontWeight="bold" mb="5px" color="white">
            محصولات غیرفعال شده - دیجیکالا
          </VuiTypography>
          <VuiBox mb={2}>
            <VuiBox display="flex" alignItems="center">
              <BsCheckCircleFill color="green" size="15px" mr="5px" />
              <VuiTypography variant="button" color="text" fontWeight="medium" ml="5px" mr="2px">
                10مورد اخیر
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        </VuiBox>
        <VuiBox color="text" px={2}>
          <IconButton sx={{color:'#ffffff',cursor: "pointer", fontWeight: "bold" }} onClick={()=>setRefresh((prev)=>!prev)}>
            <Autorenew/>
          </IconButton>
        </VuiBox>
      </VuiBox>
      <VuiBox>
        {
          syncLogData && !!syncLogData?.results.length ?
            syncLogData?.results.map((item)=>{
             return (<TimelineItem
                icon={<IoBan size="16px" color={palette.error.main} />}
                title={item?.operationId?`#${item?.operationId}`:'نامشخص'}
                dateTime={persianDate(new Date(item?.loggedAt))}
              />)
            })
            :''
        }

        <Box flex xs={12} sx={{display:'flex',textAlign:'center'}} alignContent={'center'} justifyContent={'center'} alignItems={'center'}>
          <Pagination  color={"primary"} count={syncLogData?.totalResults?Math.ceil(syncLogData?.totalResults/syncLogData?.perPage):10} variant="outlined" onChange={(e,p)=>changePage(p)} />
        </Box>
      </VuiBox>
    </Card>
  );
}

export default OrdersOverview;
