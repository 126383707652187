import axios from "axios";
import ApiList from "./ApiList";
//import { myStore } from "../redux/store";

//export const ApiBaseURL = "http://localhost:3000";
export const ApiBaseURL = typeof window !== "undefined" && window?.localStorage?.getItem("s_is_local")? "http://localhost:3000": "https://api.shazdehkoochooloo.com";
//export const ApiBaseURL = "http://api.shazdehkoochooloo.com";

export default class Network {

  constructor() {
    //this.token = myStore.getState().userTokenReducer || (window?.localStorage.getItem('token') && JSON.parse(window?.localStorage.getItem('token')))
    this.token = typeof window !== "undefined" ? window?.localStorage?.getItem("s_token") && JSON.parse(window?.localStorage?.getItem("s_token")):null;
  }

  async LogOut(redirect=false) {
    // await axios({ url: ApiURL + url, data: body, method:'delete', headers: {Authorization: this.token.accessToken} })
    /*myStore.dispatch({type:'REMOVE_USER_TOKEN'})
    myStore.dispatch({type:'REMOVE_USER_INFO'})*/
    if(typeof window !== "undefined")
      window?.localStorage?.removeItem("s_token");
    if(redirect)
    document.location.href="/login"
  }

  async logoutAndRedirect() {
    console.log('logging out and redirect');
    try{
      await this.CallApi({ url: ApiList.auth.signOut + `/${this.token.deviceId}`, method: "delete" },true);
    }
    catch (e) {
      console.log('logout and redirect error');
    }
    console.log('after call api')
    if(typeof window !== "undefined")
      window?.localStorage?.removeItem("s_token");
    window.location.href = "/login";
    console.log('just before redirect')
  }

  async CallApi({
                  url, body, method = "post", content = "application/json",
                  responseType,
                  onUploadProgress,
                  header = {
                    Authorization: this.token ? `Bearer ${this.token.accessToken}` : undefined,
                  },
                },logoutMode=false) {
    header["Content-Type"] = content || "application/json";
    if (responseType) header.responseType = responseType;
    try {
      const { data } = await axios({ url: url.startsWith(ApiBaseURL)?url: ApiBaseURL + url, data: body, method, headers: header,responseType, onUploadProgress });
      return data;
    } catch (e) {
      //console.log(e);
      if (e.response?.status === 401) await this.LogOut();
      const messages = e.response && e.response.data && (e.response.data.message || null);
      const error = {
        ...e.response?.data,
        ok: false,
        status:e.response?.status,
        message: (typeof messages == "array" ? messages[0] : messages || "Error On Fecthing Data"),
      };
      return error;
    }
  }

}