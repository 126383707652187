/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import React, { useEffect, useState } from "react";
import { setDirection, useVisionUIController } from "../../context";
import AdobeXD from "../../examples/Icons/AdobeXD";
import axios from "axios";
import { apiList, downloadBookQueueBaseUrl } from "../../variables/api";
import Icon from "@mui/material/Icon";
import { persianDate } from "../../utils/PersianDate";
import VuiProgress from "../../components/VuiProgress";
import { Book, Category, CloudDownload, CloudUpload, Layers, Search } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { LinearProgress, TextField } from "@mui/material";
import VuiInput from "../../components/VuiInput";
import CarInformations from "../profile/components/CarInformations";
import Grid from "@mui/material/Grid";
import FileUploadButton from "../books/components/FileUploadButton/FileUploadButton";
import Button from "@mui/material/Button";
import { HybridExcelUploadModal } from "../books/components/HybridExcelUploadModal/HybridExcelUploadModal";
import VuiButton from "../../components/VuiButton";
import ProductCategoryMapping from "../product/components/ProductCategoryMapping/ProductCategoryMapping";
import GeneralDialog from "../../components/GeneralDialog";
import BookPublishers from "../books/components/BookPublishers/BookPublishers";

function moduleNameEnum(moduleName,){
  switch (moduleName){
    case 'GbookService':return 'جی‌بوک'
    case 'BazarketabService':return 'بازارکتاب'
    case 'SibookService':return 'سی‌بوک'
    default : return moduleName
  }
}
function multipleModuleNames(multipleModules){
  return 'ترکیبی('+multipleModules.map((item)=>moduleNameEnum(item?.moduleName)).join('،')+')';

}
function moduleIcon(moduleName){
  switch (moduleName){
    case 'GbookService':return (
      <img width={'20px'} height={'20px'} src={'/gbook.png'}/>
    );
    case 'BazarketabService':return (
      <img width={'20px'} height={'20px'} src={'/bazar-ketab.png'}/>
    );
    case 'SibookService':return (
      <img width={'20px'} height={'20px'} src={'/30book.png'}/>
    );
    default : return <img width={'20px'} height={'20px'} src={'/favicon.png'}/>
  }
}
function queueStatus(status){
  switch (status){
    case -1:return 'خطا/متوقف';
    case 0:return 'در انتظار';
    case 1:return 'وقفه';
    case 2:return 'در حال اجرا';
    case 3:return 'تکمیل';
    default : return 'نامشخص'
  }
}
function calculateStatusPercentage(data) {
  if (!data || !data.isbns || !Array.isArray(data.isbns) || data.isbns.length === 0) {
    return 0; // Return 0 if the data or isbns array is missing or empty
  }

  const totalIsbns = data.isbns.length;
  const status3Isbns = data.isbns.filter(isbn => isbn.status === 3).length;

  if (totalIsbns === 0) {
    return 0; // Return 0 if there are no isbns
  }

  return ((status3Isbns / totalIsbns) * 100).toFixed(2);
}
function Completion({ value, color }) {
  return (
    <VuiBox display="flex" flexDirection="column" alignItems="flex-start">
      <VuiTypography variant="button" color="white" fontWeight="medium" mb="4px">
        {value}%&nbsp;
      </VuiTypography>
      <VuiBox width="8rem">
        <VuiProgress value={value} color={color} sx={{ background: "#2D2E5F" }} label={false} />
      </VuiBox>
    </VuiBox>
  );
}
function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

const SearchField = ({ onSearch }) => {

  return (
    <VuiInput
      placeholder="شابک را وارد کنید"
      icon={{ component: "circularProgress", direction: "left" }}
      sx={({ breakpoints }) => ({
        backgroundColor: "info.main !important",
      })}
      onKeyUp={(e)=>{
        let enterKey = 13; //Key Code for Enter Key
        if (e.which == enterKey){
          //Do you work here
          onSearch(e.target.value);
        }
      }}

    />
  );
};

function SingleScrapeResult({ resultObject }){
  if(!resultObject || !resultObject?.ok || !resultObject?.output || resultObject?.output?.rawTableRows?.length) return null;
  const columns=[
    { name: "عنوان", align: "left" },
    { name: "مقدار", align: "left" }
  ]
  const componentOutput=[];
  for(let key in resultObject?.output){
    const item=resultObject?.output[key];
    const rows=item.rawTableRows?.map((item)=>{
      return {
        'عنوان':<VuiBox display="flex" alignItems="center">

          <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
            {item.title}:
          </VuiTypography>
        </VuiBox>,
        'مقدار':<VuiBox display="flex" alignItems="center">
          <VuiTypography pl="16px" color="text" variant="button" fontWeight="regular">
            {item.value}
          </VuiTypography>
        </VuiBox>
      }
    })
    rows.push({
      'عنوان':<VuiBox display="flex" alignItems="center">

        <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
          وضعیت موجودی:
        </VuiTypography>
      </VuiBox>,
      'مقدار':<VuiBox display="flex" alignItems="center">
        <VuiTypography pl="16px" color={item?.bookInfo?.inStock?'success':'error'} variant="button" fontWeight="regular">
          {item?.bookInfo?.inStock?'موجود':'ناموجود'}
        </VuiTypography>
      </VuiBox>
    });
    rows.push({
      'عنوان':<VuiBox display="flex" alignItems="center">

        <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
          مرجع:
        </VuiTypography>
      </VuiBox>,
      'مقدار':<VuiBox display="flex" alignItems="center">
        <VuiTypography pl="16px" color="text" variant="button" fontWeight="regular">
          {moduleNameEnum(item.moduleName)}
        </VuiTypography>
      </VuiBox>
    });
    componentOutput.push( <Grid
      container
      spacing={3}
      alignItems={'center'}
      sx={({ breakpoints }) => ({
        [breakpoints.only("xl")]: {
          gridTemplateColumns: "repeat(2, 1fr)",
        },
        marginTop:3
      })}
    >
      <Grid
        item
        xs={12}
        xl={4}
        xxl={4}
        sx={({ breakpoints }) => ({
          minHeight: "400px",
          [breakpoints.only("xl")]: {
            gridArea: "1 / 1 / 2 / 2",
          },
        })}
      >
        <Card>
          <img style={{borderRadius:'5px'}} src={apiList['download-image']+`/${item.moduleName}?url=`+item.bookInfo?.images?.[0]??'no-book.png'} />
          <a href={item?.singleItemUrl??'#'} title={item.bookInfo?.title} target={'_blank'}>
            <VuiTypography pt="16px" sx={{textAlign:'center'}} color="text" variant="button" fontWeight="regular">
              {item.bookInfo?.title}
            </VuiTypography>
          </a>
          <VuiTypography pt="16px" sx={{textAlign:'center'}} color="white" variant="button" fontWeight="medium">
            {moduleNameEnum(item.moduleName)}
          </VuiTypography>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        xl={8}
        xxl={8}
        sx={({ breakpoints }) => ({
          [breakpoints.only("xl")]: {
            gridArea: "2 / 1 / 3 / 3",
          },
        })}
      >
        <Card>
          <Table columns={columns} rows={rows} />
        </Card>
      </Grid>
    </Grid>)
  }


  return componentOutput;
}

function Tables() {
  const [, dispatch] = useVisionUIController();
  const [queueData, setQueueData] = useState(null);
  const [singleScrape, setSingleScrape] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [singleIsLoading, setSingleIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const handleCloseDialog = () => {
    setGeneralDialogConfig((prevState) => ({ ...prevState, open: false }));
  };

  const [generalDialogConfig, setGeneralDialogConfig] = useState({
    open: false,
    /*title: "ارسال پاسخ",*/
    body: null,
    onClose: handleCloseDialog,
    onConfirm: handleCloseDialog,
  });


  //const { columns, rows } = authorsTableData;
  //const { columns: prCols, rows: prRows } = projectsTableData;
  const columns=[
    { name: "سایت", align: "left" },
    { name: "تاریخ ایجاد", align: "left" },
    { name: "وضعیت", align: "left" },
    { name: "تعداد شابک", align: "left" },
    { name: "درصد پیشرفت", align: "center" },
    { name: "دانلود", align: "center" },
  ]

  function createBookQueueRows(){
    if(!queueData || !queueData?.ok || !queueData?.results?.length) return;
    const newRows=[];
    for(let key in queueData?.results){
      const queue=queueData.results[key];

      newRows.push(
        {
          'سایت': (
            <VuiBox display="flex" alignItems="center">
              {moduleIcon(queue.moduleName)}
              <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
                {!queue.isHybrid?moduleNameEnum(queue.moduleName):multipleModuleNames(queue.multipleModules)}
              </VuiTypography>
            </VuiBox>
          ),
          'تاریخ ایجاد': (
            <VuiTypography variant="button" color="white" fontWeight="medium">
              {persianDate(queue?.createdAt)}
            </VuiTypography>
          ),
          'وضعیت': (
            <VuiTypography variant="button" color="white" fontWeight="medium">
              {queueStatus(queue?.status)}
            </VuiTypography>
          ),
          'تعداد شابک': (
            <VuiTypography variant="button" color="white" fontWeight="medium">
              {queue?.isbns?.length??'هیچی'}
            </VuiTypography>
          ),
          'درصد پیشرفت': <Completion value={calculateStatusPercentage(queue)} color={calculateStatusPercentage(queue)!==100?"info":"success"} />,
          'دانلود': queue.status===3?
              <IconButton color={"success"} title={'دانلود فایل اکسل'} onClick={()=>{
                window.open(downloadBookQueueBaseUrl+`/${queue._id}`, '_blank');
              }}><CloudDownload /></IconButton>:
              <CircularProgressWithLabel value={calculateStatusPercentage(queue)} />
          ,
        }
      )
    }

    setRows(newRows);
  }

  const getBookQueueData = async () => {
    setIsLoading(true);
    try {
      const result = await axios({
        method: "post",
        url: apiList["search-book-queues"],
      });
      if (result && result.data) {
        setQueueData(result.data);
      }
    }
    catch (e) {

    }
    setIsLoading(false);
  }
  const scrapeSingle = async (isbn,moduleName='null') => {
    if(isbn.trim()==='') return;//todo:add Snackbar
    setSingleIsLoading(true);
    setSingleScrape(null);
    const result = await axios({
      method: "post",
      url: apiList["search-book"]+`/${moduleName}`,
      data:{
        isbn
      }
    });
    if (result && result.data) {
      setSingleScrape(result.data);
    }
    setSingleIsLoading(false);
  };



  useEffect(() => {
    setDirection(dispatch, "rtl");
    getBookQueueData();
    //return () => setDirection(dispatch, "ltr");
  }, []);

  useEffect(() => {
    const interval=setInterval(()=>{
      getBookQueueData()
    },5000)
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    createBookQueueRows()
  }, [queueData]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card sx={{marginBottom:3}}>
          <SearchField onSearch={scrapeSingle} />

          <Grid sx={{mt:2,'& span':{overflow:'hidden'}}} >
            {singleIsLoading?<LinearProgress color={'info'} />:null}
          </Grid>
        </Card>

        <SingleScrapeResult resultObject={singleScrape}  />
        <Card sx={{marginTop:'10px',minHeight:'400px'}}>
          <VuiBox display="flex" justifyContent="space-between" alignItems="center">
            <VuiTypography variant="lg" color="white">
              جدول عملیات های دسته ای
            </VuiTypography>
            <VuiBox display={'flex'} justifyContent={'space-between'}>
              {/*<FileUploadButton moduleName={'GbookService'} /> &nbsp;*/}
              <Button component="span" variant="contained" color="primary" onClick={(e)=>setIsModalOpen(true)}>
                <Layers color={'white'} sx={{mr:1}}/>
                <VuiTypography variant="" color="white">
                  آپلود فایل اکسل
                </VuiTypography>
              </Button>
              <VuiBox color="text" px={2}>
                <VuiButton variant={'contained'} color={'dark'} onClick={() => {
                  setGeneralDialogConfig((prevState) => ({
                    ...prevState,
                    body: <BookPublishers
                      onSuccess={generalDialogConfig.onClose} onClose={generalDialogConfig.onClose}
                    />,
                    open: true,
                  }));
                }}><Book />&nbsp;
                  ناشران سی&zwnj;بوک</VuiButton>
              </VuiBox>
            </VuiBox>
          </VuiBox>
          <VuiBox
            sx={{
              "& th": {
                borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                  `${borderWidth[1]} solid ${grey[700]}`,
              },
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
              },
            }}
          >
            <Table columns={columns} rows={rows} />
          </VuiBox>
        </Card>
      </VuiBox>
      <Footer />
      <HybridExcelUploadModal isOpen={isModalOpen} onClose={()=>setIsModalOpen(false)} />
      <GeneralDialog maxWidth={"sm"} fullContentMode={true} body={generalDialogConfig.body}
                     open={generalDialogConfig.open}
                     onClose={generalDialogConfig.onClose} />
    </DashboardLayout>
  );
}

export default Tables;
