const ApiList = {
    auth: {
        login:"/auth/sign-in/admin-support",
        devices: "/auth/devices",
        signOut: "/auth/signOut",
        "me": "/auth/me",
        update: "/users/updateMe",
        updatePassword: "/users/updateMyPassword"
    },
    authentication: {
        search: "/verification/requests/search",
        attachment: "/verification/requests/getAttachment",
        updateStatus: '/verification/requests/updateStatus',
        delete: "/verification/requests/delete"
    },
    accountTypes: {
        search: "/account-types/search",
        update: "/account-types/update",
    },
    users: {
        changeRole: "/roles/setUserRoles",
        assignUserDepartment: "/support/tickets/assignUserDepartment",
        unAssignUserDepartment: "/support/tickets/unAssignUserDepartment",
        search: '/users/search',
        createAdmin: "/users/createUser",
        updateStatus: "/users/updateStatus"
    },
    settings: {
        role: {
            search: "/roles/search",
            update: "/roles/update",
        },
        permissions: {
            search: "/roles/searchPermissions",
            userUpdate: "setUserPermissions",
            setUserPermissions: "/roles/setUserPermissions"
        }
    },
    notification: {
        create: "/notification/create",
        search: "/notification/search",
        delete: "/notification/delete"
    },
    assets: {
        search: '/asset/adminSearch',
        obstruction: '/asset/administrativeFreezeUserAsset',
        setFree: '/asset/administrativeUnfreezeUserAsset',
        updateAssetStatus: "/asset/updateActiveStatus"

    },
    support: {
        search: "/support/search",
        create: "/support/create",
        delete: "/support/delete",
        update: "/support/update",
        category: "/categories/search",
        newCategory: "/categories/create",
        updateCategory: "/categories/update",
        deleteCategory: "/categories/delete",
        catType: "/categories/getAllTypes"
    },
    ticket: {
        search: '/support/tickets/search',
        ticket: '/support/tickets',
        delete:'/support/tickets/delete',
        updateStatus: "/support/tickets/updateStatus",
        assign: "/support/tickets/assign",
        hide: "/support/tickets/hideMessage",
        unHide: '/support/tickets/unHideMessage',
        leave: "/support/tickets/leave",
        getAttachment: "/support/tickets/getAttachment",
        addNote: "/support/tickets/addAdminNote"
    },
    finance: {
        depositAddress: {
            create: '/deposit-address/create',
            search: '/deposit-address/search',
            update: '/deposit-address/update',
        },
        dashboard: {
            chart: '/chart/admin/daysGone',
            main: '/dashboard/admin/mainTransactions'
        },
        settings: {
            search: '/finance-setting/search',
            update: '/finance-setting/update'
        },
        systemWallets: {
            search: "/system-wallet/search",
            update: '/system-wallet/update',
            create: '/system-wallet/create',

        },
        protocols: {
            search: "/protocol/search",
            update: '/protocol/update',
            create: '/protocol/create',

        },
        transactions: {
            search: '/transaction/search',
            updateStatus: '/transaction/updateStatus'

        },
        transfer: {
            search: '/transfer/search',
            updateStatus: '/transfer/updateStatus',
        },
        merchant: {
            search: '/merchant/searchOrders',
            updateStatus: '/merchant/updateStatus',

        },
        walletProtocols: {
            search: "/wallet-protocol/search",
            update: '/wallet-protocol/update',
            create: '/wallet-protocol/create',

        },
        exchange: {
            search: "/market/otc/searchOrders",
            update: "/market/otc/update",
        },
        deposit: {
            search: '/deposit/search',
            verification: '/deposit/checkVerification',
            updateStatus: "/deposit/updateStatus",
            update: "/deposit/update",

        },
        withdraw: {
            search: '/withdraw/search',
            updateStatus: '/withdraw/updateStatus',
        }
    },
    dashboard: {
        main: '/dashboard/admin/main',
        getPendingTasks:'/dashboard/admin/getPendingTasks'
    },
    forms: {
        search: "/form/search",
        update: "/form/update",
        delete: "/form/delete",
    },
    getPermisions: "/roles/getMyPermissions",
    language: {
        search: "/system-language/search",
        create: "/system-language/create",
        update: "/system-language/update",
        delete: "/system-language/delete",

    },
    merchant: {
        search: "/merchant/search",
        updateStatus: "/merchant/updateStatus"
    },
    cms: {
        category: {
            create: '/cms/category/create',
            update: '/cms/category/update',
            delete: '/cms/category/delete',
            search: '/cms/category/search',
        },
        comments: {
            search: '/cms/comment/adminSearch',
            update: "/cms/comment/update",
            create: "/cms/comment/create",
            delete: "/cms/comment/delete"
        },
        post: {
            create: "/cms/post/create",
            update: "/cms/post/update",
            search: "/cms/post/search",
            delete: '/cms/post/delete',
        },
        mediaCenter: {
            uploadPhoto: "/cms/media-center/uploadPhoto",
            uploadAsset: "/cms/media-center/uploadAsset",
            uploadFile: "/cms/media-center/uploadFile",
            search: "/cms/media-center/search",
            delete: "/cms/media-center/delete"
        }
    },
    vault:{
        search:'/vault/search',
        add:'/vault/adminAddToVault',
        sub:'/vault/adminSubFromVault'
    },
    market: {
        config: '/exchange-market/getMarketConfig',
        search: '/exchange-market/search',
        update: '/exchange-market/update',
        create: '/exchange-market/create',
        otc:{
            search: '/market/otc/adminSearchOtcMarkets',
            create: '/market/create',
            update: '/market/update',
        }
    },
    currency:{
        search:"/currency/search",
        create:"/currency/create",
        update:"/currency/update",
    },
    administrativeReports:{
        search:"/administrative-reports/search",
        resolveUnknownDeposit:"/deposit/resolve-unknown-deposit"
    }
}

export default ApiList