import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Note, Phone, Refresh, Textsms } from "@mui/icons-material";
import VuiInput from "../../../../components/VuiInput";
import GradientBorder from "../../../../examples/GradientBorder";
import { useEffect, useState } from "react";
import radialGradient from "../../../../assets/theme/functions/radialGradient";
import borders from "../../../../assets/theme/base/borders";
import palette from "../../../../assets/theme/base/colors";
import Text from "../../../../assets/theme/components/button/text";
import MenuItem from "@mui/material/MenuItem";
import { debounce, OutlinedInput, Select } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useDebounce } from "use-debounce";
import Button from "@mui/material/Button";
import VuiButton from "../../../../components/VuiButton";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export function ProductFilters({filters,setFilters,categories}){
  const theme = useTheme();
  const [text,setText]=useState('');
  const [debouncedText] = useDebounce(text, 1000);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [debouncedSelectedCategories] = useDebounce(selectedCategories, 1000);

  const [isActive, setIsActive] = useState('');
  const [debouncedIsActive] = useDebounce(isActive, 1000);

  const [hasStock, setHasStock] = useState('');
  const [debouncedHasStock] = useDebounce(hasStock, 1000);

  const initialState={
    text:'',
    selectedCategories:[],
    isActive:'',
    hasStock:'',
    isSyncActive:'',
    'lastSyncData.wasOk':'',
    'data.id_default_image':'',
  };
  const [state, setState] = useState(initialState);
  const [debouncedState] = useDebounce(state, 1000);

  const handleResetAllStates=()=>{

  }

  useEffect(()=>{
    const newState={};
    newState['data.id_category_default']=state.selectedCategories?.length?{$in:state.selectedCategories.map((item)=>`${item}`)}:undefined;

    newState['$or']=state.text?.length?[{'data.name':{$regex:state.text}}
      ,{'data.reference':{$regex:state.text}}
      ,{'lastSyncData.remoteProductId':+state.text}
    ]:undefined;
    newState['data.active']=state.isActive!==''?state.isActive:undefined;
    newState['data.quantity']=state.hasStock!==''?state.hasStock==='0'?{$eq:'0'}:{$ne:'0'}:undefined;
    newState['isSyncActive']=state.isSyncActive!==''?state.isSyncActive==='1'?true:{$ne:true}:undefined;
    newState['data.id_default_image']=state['data.id_default_image']!==''?state['data.id_default_image']==='1'?{$ne:''}:{$eq:''}:undefined;
    newState['lastSyncData.wasOk']=state['lastSyncData.wasOk']!==''?state['lastSyncData.wasOk'] === '1':undefined;

    setFilters((prev)=>({
      ...prev,
      ...newState
    }))
  },[debouncedState])
  const handleChangeCategories = (event) => {
    const {
      target: { value },
    } = event;
    setState(
      // On autofill we get a stringified value.
      (prev)=>({...prev,selectedCategories: typeof value === 'string' ? value.split(',') : value,})
    );
  };
  return (
    <Card sx={{marginBottom:3}}>
      <Grid container justifyContent={'space-between'} spacing={2} alignItems={'center'}>
        <Grid item md={8} container justifyContent={'flex-start'} spacing={2} alignItems={'center'}>
          <Grid item>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle,
              )}
            >
              <VuiInput
                icon={{ component: <Note />, direction: "left" }}
                type="text"
                placeholder="بارکد/عنوان/کد مقصد"
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
                value={state.text}
                onChange={(e) => setState(prevState => ({...prevState,text: e.target.value}))}
                onKeyPress={(event) => {
                  /*if (event.charCode === 13) {
                    handleOtp();
                  }*/
                }}
                autoFocus
                inputProps={{
                  autocomplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </GradientBorder>
          </Grid>
          <Grid item>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle,
              )}
            >
              <Select
                multiple
                displayEmpty
                value={state.selectedCategories}
                onChange={handleChangeCategories}
                input={<VuiInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>لطفا یک دسته بندی را انتخاب کنید</em>;
                  }
                  const selectedCategories=categories.filter((item)=>selected.indexOf(item.originalId)!==-1)
                  console.log(categories);
                  console.log(selectedCategories);
                  const toBeRendered=[];
                  for(let key in selectedCategories) {
                    toBeRendered.push(selectedCategories[key]?.title);
                  }
                  return toBeRendered.join(', ');
                }}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'بدون برچسب' }}
              >
                <MenuItem disabled value="">
                  <em>لطفا یک دسته بندی را انتخاب کنید</em>
                </MenuItem>
                {!!categories && categories?.map((item) => (
                  <MenuItem
                    key={item.originalId}
                    value={item.originalId}
                    style={getStyles(item.originalId, selectedCategories, theme)}
                  >
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </GradientBorder>
          </Grid>
          <Grid item>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle,
              )}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={state.isActive}
                label="وضعیت محصول"
                onChange={(e)=>setState(prevState => ({...prevState,isActive: e.target.value}))}
                input={<VuiInput />}
                displayEmpty
              >
                <MenuItem value="">
                  <em>وضعیت محصول</em>
                </MenuItem>
                <MenuItem value={'1'}>محصول فعال</MenuItem>
                <MenuItem value={'0'}>محصول غیرفعال</MenuItem>
              </Select>
            </GradientBorder>
          </Grid>
          <Grid item>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle,
              )}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={state.hasStock}
                label="وضعیت موجودی"
                onChange={(e)=>setState(prevState => ({...prevState,hasStock: e.target.value}))}
                input={<VuiInput />}
                displayEmpty
              >
                <MenuItem value="">
                  <em>وضعیت موجودی</em>
                </MenuItem>
                <MenuItem value={'1'}>موجود</MenuItem>
                <MenuItem value={'0'}>ناموجود</MenuItem>
              </Select>
            </GradientBorder>
          </Grid>
          <Grid item>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle,
              )}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={state.isSyncActive}
                label="فعال بودن سینک"
                onChange={(e)=>setState(prevState => ({...prevState,isSyncActive: e.target.value}))}
                input={<VuiInput />}
                displayEmpty
              >
                <MenuItem value="">
                  <em>وضعیت سینک</em>
                </MenuItem>
                <MenuItem value={'1'}>سینک فعال</MenuItem>
                <MenuItem value={'0'}>سینک غیرفعال</MenuItem>
              </Select>
            </GradientBorder>
          </Grid>
          <Grid item>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle,
              )}
            >
              <Select
                labelId="syncresult-label"
                id="syncresult"
                value={state['lastSyncData.wasOk']}
                label="نتیجه سینک"
                onChange={(e)=>setState(prevState => ({...prevState,'lastSyncData.wasOk': e.target.value}))}
                input={<VuiInput />}
                displayEmpty
              >
                <MenuItem value="">
                  <em>موفقیت سینک</em>
                </MenuItem>
                <MenuItem value={'1'}>سینک موفق</MenuItem>
                <MenuItem value={'0'}>سینک ناموفق</MenuItem>
              </Select>
            </GradientBorder>
          </Grid>
          <Grid item>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle,
              )}
            >
              <Select
                labelId="hasimage-label"
                id="hasimage"
                value={state['data.id_default_image']}
                label="عکس"
                onChange={(e)=>setState(prevState => ({...prevState,'data.id_default_image': e.target.value}))}
                input={<VuiInput />}
                displayEmpty
              >
                <MenuItem value="">
                  <em>عکس</em>
                </MenuItem>
                <MenuItem value={'1'}>با عکس</MenuItem>
                <MenuItem value={'0'}>بدون عکس</MenuItem>
              </Select>
            </GradientBorder>
          </Grid>
        </Grid>
        <Grid item md={4} container justifyContent={'flex-end'} spacing={2} alignItems={'center'}>
          <Grid item>
            <VuiButton onClick={()=>setState(initialState)} variant={'contained'} color={'primary'}>
              <Refresh />&nbsp;
              ریست فیلترها
            </VuiButton>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}