import React, { useState } from 'react';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import axios from 'axios';
import VuiTypography from "../../../../components/VuiTypography";
import { CloudUpload } from "@mui/icons-material";
import { apiList } from "../../../../variables/api";
import CircularProgress from "@mui/material/CircularProgress";

const FileUploadButton = ({ moduleName = "GbookService" }) => {
  const [progress, setProgress] = useState(0);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        const formData = new FormData();
        formData.append('excel_file', file);

        const response = await axios.post(apiList['create-queue-by-excel']+`/${moduleName}`, formData, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(percentCompleted);
          },
        });

        // Handle the response from the server if needed
        console.log('Upload successful:', response.data);
      } catch (error) {
        console.error('Upload failed:', error);
      } finally {
        setProgress(0); // Reset progress after completion or failure
      }
    }
  };

  return (
    <div>
      <input
        disabled={!!progress}
        type="file"
        accept=".xlsx"
        style={{ display: 'none' }}
        id="file-upload-input"
        onChange={handleFileChange}
      />
      <label htmlFor="file-upload-input">
        <Button component="span" variant="contained" color="primary">
          {
            !progress?
              <CloudUpload color={'white'} sx={{mr:1}}/>:
              <CircularProgress color={'white'} title={'در حال آپلود'} size={'1.5em'} sx={{mr:1}}/>
          }
          <VuiTypography variant="" color="white">
            آپلود اکسل
          </VuiTypography>
        </Button>
      </label>
      {progress > 0 && (
        <LinearProgress variant="determinate" color={'info'} value={progress} style={{ marginTop: '10px' }} />
      )}
    </div>
  );
};

export default FileUploadButton;
