/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { Card, Stack } from "@mui/material";
// @mui material components
import Grid from "@mui/material/Grid";
import colors from "assets/theme/base/colors";

// Vision UI Dashboard React base styles
import linearGradient from "assets/theme/functions/linearGradient";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiProgress from "components/VuiProgress";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard React contexts
import { setDirection, useVisionUIController } from "context";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import BarChart from "examples/Charts/BarCharts/BarChart";

// Data
import LineChart from "examples/Charts/LineCharts/LineChart";
import Footer from "examples/Footer";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import OrderOverview from "layouts/rtl/components/OrderOverview";
import Projects from "layouts/rtl/components/Projects";
import ReferralTracking from "layouts/rtl/components/ReferralTracking";
import SatisfactionRate from "layouts/rtl/components/SatisfactionRate";

// Dashboard layout components
import WelcomeMark from "layouts/rtl/components/WelcomeMark";
import { barChartDataDashboard } from "layouts/rtl/data/barChartData";
import { barChartOptionsDashboard } from "layouts/rtl/data/barChartOptions";
import { lineChartDataDashboard } from "layouts/rtl/data/lineChartData";
import { lineChartOptionsDashboard } from "layouts/rtl/data/lineChartOptions";
import { useEffect, useState } from "react";
import { FaShoppingCart } from "react-icons/fa";

// React icons
import { IoIosRocket } from "react-icons/io";
import {
  IoAlertCircle,
  IoBuild,
  IoCheckmarkDone,
  IoDocumentText,
  IoGlobe,
  IoPower,
  IoTrash,
  IoWallet,
} from "react-icons/io5";
import axios from "axios";
import { apiList } from "../../variables/api";
import { persianDate } from "../../utils/PersianDate";

function RTL() {
  const { gradients } = colors;
  const { cardContent } = gradients;
  const [, dispatch] = useVisionUIController();
  const [dashboardData, setDashboardData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const getDashboardData = async () => {
    setIsLoading(true);
    const result = await axios({
      method: "get",
      url: apiList["dashboard-data"]+'/DigikalaService',
    });
    if (result && result.data) {
      setDashboardData(result.data);
    }
    setIsLoading(false);
  };

  // Changing the direction to rtl
  useEffect(() => {
    setDirection(dispatch, "rtl");
    getDashboardData();
    return () => setDirection(dispatch, "ltr");
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "وضعیت", fontWeight: "regular" }}
                count="دیجیکالا"
                percentage={{
                  color: dashboardData && dashboardData?.settings?.options?.enabled ? "success":"error",
                  text: (dashboardData && dashboardData?.settings?.options?.enabled ? "فعال" : "غیرفعال")
                }}
                icon={{ color: "info", component: <IoPower size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "پردازش موفق" }}
                count="دیجیکالا"
                percentage={{ color: "success",
                  text: (dashboardData && dashboardData?.ok ? `${dashboardData?.success?.totalResults}` : "دریافت نشده")
              }}
                icon={{ color: "info", component: <IoCheckmarkDone size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "ناموجود شد" }}
                count="دیجیکالا"
                percentage={{ color: "error", text: (dashboardData && dashboardData?.ok ? `${dashboardData?.deactivated?.totalResults}` : "دریافت نشده") }}
                icon={{ color: "info", component: <IoTrash size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "خطاها" }}
                count="دیجیکالا"
                percentage={{ color: "error", text:(dashboardData && dashboardData?.ok ? `${dashboardData?.error?.totalResults}` : "دریافت نشده") }}
                icon={{ color: "info", component: <IoAlertCircle size="20px" color="white" /> }}
              />
            </Grid>
          </Grid>
        </VuiBox>

        <Grid container mb={3} spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Projects syncLogData={(dashboardData && dashboardData?.ok ? dashboardData?.recent : null)} doRefresh={getDashboardData} moreRoutePath={'/details?mn=DigikalaService'} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OrderOverview syncLogData={(dashboardData && dashboardData?.ok ? dashboardData?.deactivated : null)} doRefresh={getDashboardData} moreRoutePath={'/details?mn=DigikalaService'} />
          </Grid>
        </Grid>
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7}>
              <Card>
                <VuiBox sx={{ height: "100%" }}>
                  <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                    نمودار همگامسازی در 7روز گذشته
                  </VuiTypography>
                  <VuiBox sx={{ height: "310px" }}>
                    {dashboardData && dashboardData?.successChart && dashboardData?.deactivatedChart && <LineChart
                      lineChartData={[
                        {
                          name: "همگام سازی های موفق",
                          data: Object.keys(dashboardData?.successChart).map((key) => (dashboardData?.successChart?.[key]?.count ?? 0)),
                        },
                        {
                          name: "اتمام موجودی",
                          data: Object.keys(dashboardData?.deactivatedChart).map((key) => (dashboardData?.deactivatedChart?.[key]?.count ?? 0)),
                        },
                      ]}
                      lineChartOptions={{ ...lineChartOptionsDashboard,xaxis: {
                          type: "datetime",
                          categories:Object.keys(dashboardData?.successChart).map((key) => (persianDate(new Date(dashboardData?.successChart?.[key]?.date),'YYYY/MM/DD') ?? 0)),
                          labels: {
                            style: {
                              colors: "#c8cfca",
                              fontSize: "10px",
                            },
                          },
                          axisBorder: {
                            show: false,
                          },
                          axisTicks: {
                            show: false,
                          },
                        } }}
                    />}
                  </VuiBox>
                </VuiBox>
              </Card>
            </Grid>
          </Grid>
        </VuiBox>

      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default RTL;
