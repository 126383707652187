import Card from "@mui/material/Card";
import axios from "axios";
import { apiList } from "../../../../variables/api";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import borders from "../../../../assets/theme/base/borders";
import radialGradient from "../../../../assets/theme/functions/radialGradient";
import palette from "../../../../assets/theme/base/colors";
import { Checkbox, ListItemAvatar, ListItemButton, ListSubheader, Select } from "@mui/material";
import VuiInput from "../../../../components/VuiInput";
import MenuItem from "@mui/material/MenuItem";
import GradientBorder from "../../../../examples/GradientBorder";
import VuiTypography from "../../../../components/VuiTypography";
import { useSnackbar } from "material-ui-snackbar-provider";
import VuiButton from "../../../../components/VuiButton";
import VuiBox from "../../../../components/VuiBox";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Network from "../../../../utils/Network";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import VuiSwitch from "../../../../components/VuiSwitch";
import { Note } from "@mui/icons-material";

export default function BookPublishers({onSuccess,onClose}){
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdatingSettings, setIsUpdatinSettings] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [settings, setSettings] = useState(null);
  const [showOnlyActive, setShowOnlyActive] = useState(null);
  const [search, setSearch] = useState('');
  const snackbar=useSnackbar();
  const network=new Network();
  const getAllSettings = async () => {
    console.log('is going to get sibook settings');
    setIsLoading(true);
    console.log('before request');
    const result = await network.CallApi({
      method: "get",
      url: apiList["get-settings"],
      body:null,
    });
    console.log(result);
    console.log('after request');
    if (result && result?.settings) {
      if(result.ok) {
        setSettings(result?.settings?.filter((item)=>item.key==='SibookService')?.[0]);
        console.log('new settings:',settings);
      }
    }
    setIsLoading(false);
  };
  async function handleActivate(publisher,isActive){
    setIsUpdatinSettings(true);
    //update settings locally
    setSettings((prev)=>{
      const newSettings= {
        ...prev,
        options:{
          ...prev.options,
          publishers:prev?.options?.publishers?.map(item=>(
            item?.link===publisher.link ? {...item,isActive}:item
          ))
        }
      }
      handleUpdateSettings(newSettings,false);
      return newSettings;
    })
    setIsUpdatinSettings(false);

  }
  async function handleUpdateSettings(newSettings,updateStateByResponse=false){
    //then update remote
    try{
      const result = await axios({
        method: "post",
        url: apiList["set-setting"]+`/${newSettings?._id}`,
        data:newSettings
      });
      if (result && result && result?.settings) {
        if (result.ok) {
          snackbar.showMessage('تنظیمات ذخیره شد')
          if(updateStateByResponse)
            setSettings(result?.settings);
        }
      }
    }
    catch (e) {
      console.log(e);
      snackbar.showMessage('عملیات با خطا مواجه شد')
    }
  }
  function buildSpecialListItems(currentCategory){
    if(!currentCategory?.data?.children?.length)
      return <MenuItem value={currentCategory?.originalId??currentCategory?.id}>{currentCategory?.title}</MenuItem>
    else return [
      <MenuItem disabled value={currentCategory?.originalId??currentCategory?.id}>
        <em>{currentCategory?.title}</em></MenuItem>,
      currentCategory?.data?.children.map(child=>buildSpecialListItems(child))
    ]
  }

  useEffect(() => {
    getAllSettings()
  }, [0,refresh]);

  return <Card sx={{overflowY:'scroll'}}>
    <VuiTypography color="white" variant="lg" mb="6px" gutterBottom>
      فعال سازی/غیرفعالسازی ناشران سی بوک
    </VuiTypography>
    <Grid container justifyContent={'space-between'} mb={2}>
      <Grid item sx={{flex:1,px:1}}>
        <GradientBorder
          minWidth="100%"
          borderRadius={borders.borderRadius.lg}
          padding="1px"
          backgroundImage={radialGradient(
            palette.gradients.borderLight.main,
            palette.gradients.borderLight.state,
            palette.gradients.borderLight.angle,
          )}
        >
          <VuiInput
            type="text"
            placeholder="جستجو..."
            sx={({ typography: { size } }) => ({
              fontSize: size.sm,
            })}
            value={search}
            onChange={(e) => setSearch( e.target.value)}
            onKeyPress={(event) => {
              /*if (event.charCode === 13) {
                handleOtp();
              }*/
            }}
            autoFocus
          />
        </GradientBorder>
      </Grid>
      <Grid item>
        <GradientBorder
          minWidth="100%"
          borderRadius={borders.borderRadius.lg}
          padding="1px"
          backgroundImage={radialGradient(
            palette.gradients.borderLight.main,
            palette.gradients.borderLight.state,
            palette.gradients.borderLight.angle,
          )}
        >
          <Select
            labelId="show-only-active-label"
            id="show-only-active"
            value={showOnlyActive}
            label="وضعیت سینک"
            onChange={(e)=>setShowOnlyActive(e.target?.value)}
            input={<VuiInput />}
            displayEmpty
          >
            <MenuItem value={null}>همه</MenuItem>
            <MenuItem value={true}>سینک فعال</MenuItem>
            <MenuItem value={false}>سینک غیرفعال</MenuItem>
          </Select>
        </GradientBorder>
      </Grid>
    </Grid>

    {!isLoading?
      <Grid container justifyContent={"space-between"} spacing={2} alignItems={"center"}>
        <Grid item xs={12}>
          {
            <List color={'white'}>
              {settings?.options?.publishers?.filter((publisher)=>{
                if(search.trim()!==''){
                  if(publisher?.title?.includes(search) && (showOnlyActive === null || (publisher.isActive === showOnlyActive)))
                    return publisher;
                }
                else if(showOnlyActive === null || (publisher.isActive === showOnlyActive))
                  return publisher;

              })?.map((publisher,index) => {
                const labelId = `checkbox-list-secondary-label-${index}`;
                return (
                  <ListItem
                    style={{color:'white'}}
                    key={index}
                    secondaryAction={
                    isUpdatingSettings? <CircularProgress /> :
                      <VuiBox width="8rem" textAlign="center" display={'flex'} justifyContent={'space-around'}>
                        <VuiBox mt={0.25}>
                          <VuiSwitch color="info" checked={!!publisher?.isActive} onChange={(e) => {
                            handleActivate(publisher,!publisher?.isActive)
                          }} />
                        </VuiBox>
                      </VuiBox>
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemAvatar>
                        <Avatar
                          alt={publisher?.title}
                          src={publisher?.img}
                        />
                      </ListItemAvatar>
                      <ListItemText id={labelId} primary={publisher?.title} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          }
        </Grid>
        <Grid item xs={12}>
          <VuiBox mt={4} mb={1}>
            <VuiButton onClick={() => {
              onClose();
            }} color="light" fullWidth>
              بستن
            </VuiButton>
          </VuiBox>
        </Grid>
      </Grid>
      :
    <Grid container justifyContent={'space-around'} alignContent={'center'}>
      <Grid item><CircularProgress /></Grid>
    </Grid>
    }
  </Card>
}