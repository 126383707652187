// @mui material components
import Tooltip from "@mui/material/Tooltip";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";
import VuiProgress from "components/VuiProgress";

// Images
import AdobeXD from "examples/Icons/AdobeXD";
import Atlassian from "examples/Icons/Atlassian";
import Slack from "examples/Icons/Slack";
import Spotify from "examples/Icons/Spotify";
import Jira from "examples/Icons/Jira";
import Invision from "examples/Icons/Invision";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoJira from "assets/images/small-logos/logo-jira.svg";
import logoInvesion from "assets/images/small-logos/logo-invision.svg";
import avatar1 from "assets/images/avatar1.png";
import avatar2 from "assets/images/avatar2.png";
import avatar3 from "assets/images/avatar3.png";
import avatar4 from "assets/images/avatar4.png";
import { persianAgo, persianDate } from "../../../../../utils/PersianDate";

export default function data({data}) {
  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <VuiAvatar
          src={image}
          alt="name"
          size="xs"
          sx={{
            border: ({ borders: { borderWidth }, palette: { dark } }) =>
              `${borderWidth[2]} solid ${dark.focus}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  const rows=data && data.length?data.map((item)=>{
    return {
      "محصول": (
      <VuiBox display="flex" alignItems="center">
        <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
          #{item?.operationId??'نامشخص'}
        </VuiTypography>
      </VuiBox>
    ),
      "موجودی جدید": (
      <VuiBox display="flex" py={1}>
        <VuiTypography variant="button" color="white" fontWeight="bold">
          {item?.newStockCount!==undefined?`${item.newStockCount}عدد`:'نامشخص'}
        </VuiTypography>
      </VuiBox>
    ),
      "زمان بروزرسانی": (
      <VuiTypography variant="button" color="white" fontWeight="bold">
        {persianDate(new Date(item?.loggedAt))}
      </VuiTypography>
    ),
      وضعیت: (
      <VuiBox width="8rem" textAlign="center">
        <VuiTypography color="success" variant="button" fontWeight="bold">
          {item.isOk===true?'موفق':(item.isOk===false?'ناموفق':'نامشخص')}
        </VuiTypography>
      </VuiBox>
    ),
    }
  }):[]
  return {
    columns: [
      { name: "محصول", align: "left" },
      { name: "موجودی جدید", align: "left" },
      { name: "زمان بروزرسانی", align: "center" },
      { name: "وضعیت", align: "center" },
    ],

    rows,
  };
}
